import NLS                  from "dashboard/dist/Core/NLS";
import DateTime             from "dashboard/dist/Utils/DateTime";
import Utils                from "dashboard/dist/Utils/Utils";
import Common               from "Utils/Common";

// Types
import {
    ENROLL_LOADING,
    ENROLL_LIST,
} from "Utils/Types";



// The initial State
const initialState = {
    loaded     : false,
    loading    : false,
    error      : false,
    edition    : 0,
    canCreate  : false,
    canEdit    : false,
    streches   : [],
    courses    : [],
    countries  : [],
    provinces  : {},
    localities : {},
};



/**
 * Parses the Streches
 * @param {Object[]} list
 * @param {Boolean}  useTimezone
 * @returns {Object[]}
 */
function parseStreches(list, useTimezone) {
    return Utils.parseList(list, (elem) => {
        elem.buttonString = NLS.get("TYPES_ENROLL", elem.programType);

        // Parse the Course Dates
        elem.courseDates  = "";
        elem.meetingDates = "";

        let fromDate = DateTime.formatDate(elem.fromTimeDate, "dashes", useTimezone);
        let toDate   = DateTime.formatDate(elem.toTimeDate,   "dashes", useTimezone);
        let toHour   = "";

        if (elem.hasClasses) {
            elem.courseDates = NLS.format("ENROLL_COURSE_DATES", fromDate, toDate);

        } else if (fromDate === toDate) {
            const fromHour = DateTime.formatDate(elem.fromTimeFull, "time", useTimezone);
            toHour = DateTime.formatDate(elem.toTimeFull, "time", useTimezone);
            elem.meetingDates = NLS.format("ENROLL_MEETING_DATE", fromDate, fromHour, toHour);

        } else {
            const fromDateHour = DateTime.formatDate(elem.fromTimeFull, "dashesHour", useTimezone);
            const toDateHour   = DateTime.formatDate(elem.toTimeFull,   "dashesHour", useTimezone);
            toHour = DateTime.formatDate(elem.toTimeFull, "time", useTimezone);
            elem.meetingDates = NLS.format("ENROLL_MEETING_DATES", fromDateHour, toDateHour);
        }

        // Parse the Inscription Dates
        const inscFromDate = DateTime.formatDate(elem.inscFromTimeDate, "dashes", useTimezone);
        const inscToDate   = DateTime.formatDate(elem.inscToTimeDate,   "dashes", useTimezone);
        elem.inscriptionDates = NLS.format("ENROLL_INSCRIPTION_DATES", inscFromDate, inscToDate);

        // Parse the Schedules
        elem.scheduleString = "";
        elem.scheduleTypes  = [];
        if (elem.schedules.length) {
            const schedules     = Common.parseSchedules(elem.schedules, useTimezone);
            elem.scheduleString = schedules.list.length > 0 ? schedules.list.join("<br>") : "";
            elem.scheduleTypes  = schedules.types;
        } else if (toHour) {
            elem.scheduleTypes  = [ Common.getScheduleType(toHour) ];
        }

        // Parse the Includes
        const includes = [];
        if (elem.classCount > 0) {
            includes.push(NLS.pluralize("ENROLL_ENCOUNTERS", elem.classCount));
        }
        if (elem.conversations > 0) {
            includes.push(NLS.pluralize("ENROLL_CONVERSATIONS", elem.conversations));
        }
        if (elem.meetingAmount > 0) {
            includes.push(NLS.pluralize("ENROLL_MEETINGS", elem.meetingAmount));
        }
        if (elem.hasGuides) {
            includes.push(NLS.get("ENROLL_GUIDES"));
        }
        elem.includesString = includes.length ? NLS.formatJoin("ENROLL_INCLUDES", includes) : "";

        // Parse the Chats
        elem.chatsList   = [];
        elem.chatsString = "";
        for (const chat of elem.chats) {
            const fromTimeDay = DateTime.fromString(chat.timeDate, chat.fromHour, useTimezone);
            const toTimeDay   = DateTime.fromString(chat.timeDate, chat.toHour,   useTimezone);

            // @ts-ignore
            if (chat.time && !fromTimeDay.isPastDay()) {
                // @ts-ignore
                const chatDate = fromTimeDay.toString("dashes");
                // @ts-ignore
                const fromHour = fromTimeDay.toString("time");
                // @ts-ignore
                const toHour   = toTimeDay.toString("time");

                elem.chatsList.push({
                    chatID : chat.strechChatID,
                    label  : NLS.format("ENROLL_CHAT_SELECT", chatDate, fromHour, toHour),
                });
                if (!elem.chatsString) {
                    elem.chatsString = NLS.format("ENROLL_CHAT_DATES", chatDate, fromHour, toHour);
                }
            }
        }
        elem.canEnrollChat = Boolean(elem.chatsString);

        // Parse the Coaches
        const coaches = [];
        for (const coach of elem.coaches) {
            coaches.push(coach.credentialName);
        }
        elem.coachesString = coaches.length > 0 ? NLS.pluralizeList("ENROLL_COACHES", coaches) : "";

        // Parse the Price
        const prices = {};
        for (const price of elem.prices) {
            if (price.isPromotion) {
                const promotionEnd = DateTime.formatDate(price.promotionEndDate, "dashes", useTimezone);
                if (prices[price.dues]) {
                    prices[price.dues] += NLS.format("ENROLL_PRICE_PROMOTION", promotionEnd, price.priceFormat);
                } else {
                    prices[price.dues] = NLS.pluralize("ENROLL_PRICE_PROMOTION", price.dues, price.priceFormat, promotionEnd);
                }
            } else {
                prices[price.dues] = NLS.pluralize("ENROLL_PRICE_NORMAL", price.dues, price.priceFormat);
            }
        }
        const sortedPrices = Object.keys(prices)
            .sort((a, b) => b - a)  // Orden descendente
            .map(key => prices[key]); // Convertimos las llaves ordenadas en un array de valores

        const priceArr    = Object.values(sortedPrices);
        elem.pricesString = priceArr.length ? NLS.formatJoin("ENROLL_PRICE", priceArr, true) : "";

        // Parse the Quota
        elem.availableString = "";
        if (elem.availableQuota <= 10) {
            elem.availableString = NLS.pluralize("ENROLL_AVAILABLE", elem.availableQuota);
        }
    });
}



/**
 * The Actions
 * @param {Object=} state
 * @param {Object=} action
 * @returns {Object}
 */
const reducer = (state = initialState, action = {}) => {
    if (Utils.hasError(action, ENROLL_LIST)) {
        return { ...state, loading : false, error : true };
    }

    switch (action.type) {
    case ENROLL_LOADING:
        return {
            ...state,
            loading    : true,
        };

    case ENROLL_LIST:
        return {
            ...state,
            loaded     : true,
            loading    : false,
            error      : false,
            streches   : parseStreches(action.data.streches, !action.data.forAdmin),
            courses    : action.data.courses,
            countries  : action.data.countries,
            provinces  : action.data.provinces,
            localities : action.data.localities,
        };

    default:
        return state;
    }
};

export default reducer;
